// -------------------------------------
//   lineicon Mixins
// -------------------------------------

@font-face {
  font-family: 'Linearicons';
  src:url('../../fonts/Linearicons/Linearicons.eot?3mzs9j');
  src:url('../../fonts/Linearicons/Linearicons.eot?3mzs9j#iefix') format('embedded-opentype'),
    url('../../fonts/Linearicons/Linearicons.ttf?3mzs9j') format('truetype'),
    url('../../fonts/Linearicons/Linearicons.woff?3mzs9j') format('woff'),
    url('../../fonts/Linearicons/Linearicons.svg?3mzs9j#Linearicons') format('svg');
  font-weight: normal;
  font-style: normal;
}


.linearicons() {
  line-height: 16px;
  &:before, &:after {
    font-family: 'Linearicons';
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    text-decoration: inherit;
    text-rendering: auto;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.875rem;
  }
}

// -------------------------------------
//   lineicon Variables
// -------------------------------------

@icon-home: "\e600";
@icon-apartment: "\e60c";
@icon-pencil: "\e60d";
@icon-pen: "\e60f";
@icon-pencil3: "\e610";
@icon-eraser: "\e611";
@icon-pencil5: "\e613";
@icon-feather: "\e614";
@icon-pen-add: "\e618";
@icon-pen-remove: "\e619";
@icon-vector: "\e61a";
@icon-pen3: "\e61b";
@icon-eye-dropper: "\e625";
@icon-palette: "\e626";
@icon-color-sampler: "\e627";
@icon-bucket: "\e628";
@icon-magic-wand: "\e62b";
@icon-pencil-ruler2: "\e62e";
@icon-compass: "\e62f";
@icon-aim: "\e630";
@icon-drop: "\e633";
@icon-drop2: "\e635";
@icon-sun: "\e647";
@icon-moon: "\e649";
@icon-cloud: "\e64a";
@icon-database: "\e65d";
@icon-database-add: "\e65e";
@icon-database-remove: "\e65f";
@icon-database-lock: "\e660";
@icon-database-refresh: "\e661";
@icon-database-check: "\e662";
@icon-database-history: "\e663";
@icon-database-upload: "\e664";
@icon-database-download: "\e665";
@icon-server: "\e666";
@icon-shield: "\e667";
@icon-shield-check: "\e668";
@icon-shield-alert: "\e669";
@icon-shield-cross: "\e66a";
@icon-lock: "\e66b";
@icon-rotation-lock: "\e66c";
@icon-unlock: "\e66d";
@icon-key: "\e66e";
@icon-cog2: "\e673";
@icon-recycle: "\e67f";
@icon-trash2: "\e681";
@icon-heart: "\e68c";
@icon-star: "\e68d";
@icon-star-half: "\e68e";
@icon-star-empty: "\e68f";
@icon-flag: "\e690";
@icon-at-sign: "\e695";
@icon-envelope: "\e696";
@icon-envelope-open: "\e697";
@icon-paperclip: "\e698";
@icon-paper-plane: "\e699";
@icon-reply: "\e69a";
@icon-reply-all: "\e69b";
@icon-eye: "\e6a5";
@icon-eye-crossed: "\e6a6";
@icon-eye-plus: "\e6a7";
@icon-eye-minus: "\e6a8";
@icon-binoculars: "\e6a9";
@icon-binoculars2: "\e6aa";
@icon-hdd: "\e6ab";
@icon-hdd-down: "\e6ac";
@icon-hdd-up: "\e6ad";
@icon-floppy-disk: "\e6ae";
@icon-printer: "\e6b1";
@icon-shredder: "\e6b2";
@icon-file-empty: "\e6b3";
@icon-file-add: "\e6b4";
@icon-file-check: "\e6b5";
@icon-file-lock: "\e6b6";
@icon-files: "\e6b7";
@icon-copy: "\e6b8";
@icon-compare: "\e6b9";
@icon-folder: "\e6ba";
@icon-folder-search: "\e6bb";
@icon-folder-plus: "\e6bc";
@icon-folder-minus: "\e6bd";
@icon-folder-download: "\e6be";
@icon-folder-upload: "\e6bf";
@icon-folder-star: "\e6c0";
@icon-folder-heart: "\e6c1";
@icon-folder-user: "\e6c2";
@icon-folder-shared: "\e6c3";
@icon-folder-music: "\e6c4";
@icon-folder-picture: "\e6c5";
@icon-folder-film: "\e6c6";
@icon-scissors: "\e6c7";
@icon-paste: "\e6c8";
@icon-clipboard-empty: "\e6c9";
@icon-clipboard-pencil: "\e6ca";
@icon-clipboard-text: "\e6cb";
@icon-clipboard-check: "\e6cc";
@icon-clipboard-down: "\e6cd";
@icon-clipboard-left: "\e6ce";
@icon-clipboard-alert: "\e6cf";
@icon-clipboard-user: "\e6d0";
@icon-register: "\e6d1";
@icon-enter: "\e6d2";
@icon-exit: "\e6d3";
@icon-shuffle: "\e6ec";
@icon-repeat-one: "\e6ed";
@icon-repeat: "\e6ee";
@icon-equalizer: "\e6f2";
@icon-camera2: "\e704";
@icon-picture3: "\e710";
@icon-book2: "\e714";
@icon-bookmark: "\e715";
@icon-bookmark2: "\e716";
@icon-label: "\e717";
@icon-user: "\e71e";
@icon-user-plus: "\e71f";
@icon-user-minus: "\e720";
@icon-user-lock: "\e721";
@icon-users: "\e722";
@icon-users2: "\e723";
@icon-users-plus: "\e724";
@icon-users-minus: "\e725";
@icon-group-work: "\e726";
@icon-woman: "\e727";
@icon-man: "\e728";
@icon-barcode2: "\e748";
@icon-barcode3: "\e749";
@icon-cart: "\e74d";
@icon-tag: "\e755";
@icon-tags: "\e756";
@icon-credit-card: "\e759";
@icon-calculator2: "\e767";
@icon-telephone: "\e76a";
@icon-phone-wave: "\e76c";
@icon-telephone2: "\e777";
@icon-pushpin: "\e778";
@icon-map-marker: "\e77a";
@icon-map2: "\e782";
@icon-location: "\e783";
@icon-calendar-check: "\e786";
@icon-calendar-31: "\e788";
@icon-calendar-full: "\e789";
@icon-calendar-user: "\e78c";
@icon-mouse: "\e78d";
@icon-mouse-left: "\e78e";
@icon-mouse-right: "\e78f";
@icon-mouse-both: "\e790";
@icon-keyboard: "\e791";
@icon-delete: "\e794";
@icon-spell-check: "\e795";
@icon-escape: "\e796";
@icon-screen: "\e798";
@icon-signal: "\e79a";
@icon-signal-lock: "\e79b";
@icon-tablet2: "\e7ac";
@icon-laptop: "\e7ad";
@icon-launch: "\e7b0";
@icon-new-tab: "\e7b1";
@icon-window: "\e7b2";
@icon-power-switch: "\e7b8";
@icon-bubble: "\e7d6";
@icon-bubbles: "\e7d7";
@icon-bubble-dots: "\e7d8";
@icon-bubble-alert: "\e7d9";
@icon-bubble-text: "\e7db";
@icon-bubble-pencil: "\e7dc";
@icon-bubble-quote: "\e7e0";
@icon-phone-bubble: "\e7e4";
@icon-quote-open: "\e7e5";
@icon-quote-close: "\e7e6";
@icon-pulse: "\e7e9";
@icon-thermometer: "\e7f0";
@icon-skull: "\e7f4";
@icon-construction-cone: "\e7f7";
@icon-rank: "\e801";
@icon-rank2: "\e802";
@icon-rank3: "\e803";
@icon-speed-fast: "\e836";
@icon-balance: "\e839";
@icon-briefcase: "\e83a";
@icon-cube: "\e874";
@icon-glasses2: "\e878";
@icon-accessibility: "\e879";
@icon-wheelchair: "\e87a";
@icon-icons: "\e87e";
@icon-select: "\e881";
@icon-select2: "\e882";
@icon-site-map: "\e883";
@icon-earth: "\e884";
@icon-network: "\e886";
@icon-smile: "\e88a";
@icon-volume-high: "\e8b0";
@icon-mute: "\e8b4";
@icon-lan: "\e8b5";
@icon-lan2: "\e8b6";
@icon-undo: "\e8d5";
@icon-redo: "\e8d6";
@icon-jump2: "\e8d7";
@icon-undo2: "\e8d8";
@icon-redo2: "\e8d9";
@icon-sync: "\e8da";
@icon-repeat-one3: "\e8de";
@icon-return2: "\e8e1";
@icon-history: "\e8e3";
@icon-clock3: "\e8e8";
@icon-alarm2: "\e8ea";
@icon-download2: "\e8f5";
@icon-upload2: "\e8f6";
@icon-enter-up: "\e8f7";
@icon-enter-down: "\e8f8";
@icon-enter-left: "\e8f9";
@icon-enter-right: "\e8fa";
@icon-exit-up: "\e8fb";
@icon-exit-down: "\e8fc";
@icon-exit-left: "\e8fd";
@icon-exit-right: "\e8fe";
@icon-cli: "\e909";
@icon-code: "\e90b";
@icon-file-code: "\e90c";
@icon-file-image: "\e90d";
@icon-file-zip: "\e90e";
@icon-file-audio: "\e90f";
@icon-file-video: "\e910";
@icon-file-preview: "\e911";
@icon-file-charts: "\e912";
@icon-file-stats: "\e913";
@icon-file-spreadsheet: "\e914";
@icon-link2: "\e917";
@icon-unlink2: "\e918";
@icon-thumbs-up: "\e919";
@icon-thumbs-down2: "\e91c";
@icon-share: "\e91f";
@icon-magnifier: "\e922";
@icon-file-search: "\e923";
@icon-zoom-in: "\e925";
@icon-zoom-out: "\e926";
@icon-loupe: "\e927";
@icon-cross: "\e92a";
@icon-menu: "\e92b";
@icon-list: "\e92c";
@icon-list2: "\e92d";
@icon-list3: "\e92e";
@icon-menu2: "\e92f";
@icon-list4: "\e930";
@icon-menu3: "\e931";
@icon-exclamation: "\e932";
@icon-question: "\e933";
@icon-check: "\e934";
@icon-cross2: "\e935";
@icon-plus: "\e936";
@icon-minus: "\e937";
@icon-percent: "\e938";
@icon-chevron-up: "\e939";
@icon-chevron-down: "\e93a";
@icon-chevron-left: "\e93b";
@icon-chevron-right: "\e93c";
@icon-chevrons-expand-vertical: "\e93d";
@icon-chevrons-expand-horizontal: "\e93e";
@icon-chevrons-contract-vertical: "\e93f";
@icon-chevrons-contract-horizontal: "\e940";
@icon-arrow-up: "\e941";
@icon-arrow-down: "\e942";
@icon-arrow-left: "\e943";
@icon-arrow-right: "\e944";
@icon-arrow-up-right: "\e945";
@icon-arrows-merge: "\e946";
@icon-arrows-split: "\e947";
@icon-arrow-divert: "\e948";
@icon-arrow-return: "\e949";
@icon-expand: "\e94a";
@icon-contract: "\e94b";
@icon-expand2: "\e94c";
@icon-contract2: "\e94d";
@icon-move: "\e94e";
@icon-tab: "\e94f";
@icon-arrow-wave: "\e950";
@icon-expand3: "\e951";
@icon-expand4: "\e952";
@icon-contract3: "\e953";
@icon-notification: "\e954";
@icon-warning: "\e955";
@icon-notification-circle: "\e956";
@icon-question-circle: "\e957";
@icon-menu-circle: "\e958";
@icon-checkmark-circle: "\e959";
@icon-cross-circle: "\e95a";
@icon-plus-circle: "\e95b";
@icon-circle-minus: "\e95c";
@icon-percent-circle: "\e95d";
@icon-arrow-up-circle: "\e95e";
@icon-arrow-down-circle: "\e95f";
@icon-arrow-left-circle: "\e960";
@icon-arrow-right-circle: "\e961";
@icon-chevron-up-circle: "\e962";
@icon-chevron-down-circle: "\e963";
@icon-chevron-left-circle: "\e964";
@icon-chevron-right-circle: "\e965";
@icon-backward-circle: "\e966";
@icon-first-circle: "\e967";
@icon-previous-circle: "\e968";
@icon-stop-circle: "\e969";
@icon-play-circle: "\e96a";
@icon-pause-circle: "\e96b";
@icon-next-circle: "\e96c";
@icon-last-circle: "\e96d";
@icon-forward-circle: "\e96e";
@icon-eject-circle: "\e96f";
@icon-crop: "\e970";
@icon-frame-expand: "\e971";
@icon-frame-contract: "\e972";
@icon-focus: "\e973";
@icon-transform: "\e974";
@icon-grid: "\e975";
@icon-grid-crossed: "\e976";
@icon-layers: "\e977";
@icon-layers-crossed: "\e978";
@icon-toggle: "\e979";
@icon-rulers: "\e97a";
@icon-ruler: "\e97b";
@icon-funnel: "\e97c";
@icon-square: "\e98d";
@icon-plus-square: "\e98e";
@icon-minus-square: "\e98f";
@icon-percent-square: "\e990";
@icon-arrow-up-square: "\e991";
@icon-arrow-down-square: "\e992";
@icon-arrow-left-square: "\e993";
@icon-arrow-right-square: "\e994";
@icon-chevron-up-square: "\e995";
@icon-chevron-down-square: "\e996";
@icon-chevron-left-square: "\e997";
@icon-chevron-right-square: "\e998";
@icon-check-square: "\e999";
@icon-cross-square: "\e99a";
@icon-menu-square: "\e99b";
@icon-prohibited: "\e99c";
@icon-circle: "\e99d";
@icon-radio-button: "\e99e";
@icon-text-format: "\e9a0";
@icon-text-format-remove: "\e9a1";
@icon-text-size: "\e9a2";
@icon-bold: "\e9a3";
@icon-italic: "\e9a4";
@icon-underline: "\e9a5";
@icon-strikethrough: "\e9a6";
@icon-highlight: "\e9a7";
@icon-text-align-left: "\e9a8";
@icon-text-align-center: "\e9a9";
@icon-text-align-right: "\e9aa";
@icon-text-align-justify: "\e9ab";
@icon-line-spacing: "\e9ac";
@icon-indent-increase: "\e9ad";
@icon-indent-decrease: "\e9ae";
@icon-text-wrap: "\e9af";
@icon-pilcrow: "\e9b0";
@icon-direction-ltr: "\e9b1";
@icon-direction-rtl: "\e9b2";
@icon-page-break: "\e9b3";
@icon-page-break2: "\e9b4";
@icon-sort-alpha-asc: "\e9b5";
@icon-sort-alpha-desc: "\e9b6";
@icon-sort-amount-asc: "\e9b9";
@icon-sort-amount-desc: "\e9ba";
@icon-sigma: "\e9bd";
@icon-pencil-line: "\e9be";
@icon-hand: "\e9bf";
@icon-pointer-up: "\e9c0";
@icon-pointer-down: "\e9c2";
@icon-pointer-left: "\e9c3";
@icon-border-style: "\e9de";
@icon-border-all: "\e9df";
@icon-border-outer: "\e9e0";
@icon-border-inner: "\e9e1";
@icon-border-top: "\e9e2";
@icon-border-horizontal: "\e9e3";
@icon-border-bottom: "\e9e4";
@icon-border-left: "\e9e5";
@icon-border-vertical: "\e9e6";
@icon-border-right: "\e9e7";
@icon-border-none: "\e9e8";
@icon-ellipsis: "\e9e9";
@icon-superscript: "\ea69";
@icon-subscript: "\ea6a";
