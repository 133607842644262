@import "_tcg.variables.less";
@import "_lineicon.less";
@import "common/mixins.less";
@import "theme-template.less";

// -------------------------------------
//   Extended Mixins
// -------------------------------------

.user-select (@val) {
  -webkit-touch-callout:  @val;
  -webkit-user-select:    @val;
  -khtml-user-select:     @val;
  -moz-user-select:       @val;
  -ms-user-select:        @val;
  user-select:            @val;
}


// -------------------------------------
//   Common Border-radius
// -------------------------------------

.k-grid,
.k-scheduler,
.k-menu,
.k-editor {
  border-radius: @main-border-radius;
}

.k-grid > .k-grouping-header,
.k-grid-header:first-child,
.k-grid-toolbar:first-child,
.k-scheduler > .k-scheduler-toolbar:first-child {
  border-radius: @inner-border-radius @inner-border-radius 0 0;
}

.k-grid-header:first-child th.k-header:first-child,
thead.k-grid-header th.k-header:first-child,
.k-rtl thead.k-grid-header th.k-header:last-child {
  border-radius: @inner-border-radius 0 0 0;
}

.k-rtl .k-grid-header:first-child th.k-header:first-child,
.k-rtl thead.k-grid-header th.k-header:first-child,
thead.k-grid-header th.k-header:last-child {
  border-radius: 0 @inner-border-radius 0 0;
}

.k-grid-pager,
.k-scheduler-footer {
  border-radius: 0 0 @inner-border-radius @inner-border-radius;
}

.k-menu > .k-first {
  border-radius: @inner-border-radius 0 0 @inner-border-radius;
}

.k-rtl .k-menu > .k-first {
  border-radius: 0 @inner-border-radius @inner-border-radius 0;
}


// -------------------------------------
//   Treeview
// -------------------------------------

.k-widget.k-treeview {
  // color: @treeview-text-color;
  color: lighten(@treeview-text-color, 10%);
}

.k-treeview .k-in.k-state-hover {
  color: @treeview-hover-text-color;
}

.k-treeview .k-in.k-state-focused {
  background-color: darken(@c-primary, 10%);
  color: @treeview-focused-text-color;
}

.k-treeview .k-state-selected,
.k-draghandle.k-state-selected {
  .box-shadow(none);
}

.k-treeview .k-icon {
  color: @c-gray-dark;
}

// -------------------------------------
//   Tabstrip
// -------------------------------------

.k-tabstrip {
  &:focus {
    .box-shadow(none);
  }
}

.k-tabstrip-items {
  .k-loading.k-complete {
    display: none;
  }
}

.k-tabstrip-items {
  .k-state-hover .k-link {
    color: @tabstrip-tabs-hover-color;
  }
  .k-state-active .k-link {
    color: @active-text-color;
  }
  .k-state-active .k-link {
    color: @active-text-color;
    background: @widget-background-color;
  }
  .k-state-focused {
    border-color: @tabstrip-items-border;
    .box-shadow(@focused-shadow);
  }
}


.k-tabstrip-items .k-item {
  background: @tabstrip-background;
  &.k-state-active {
    background: @widget-background-color;
  }
  &.k-state-hover {
    background: @tabstrip-hover-background;
  }
}

.k-tabstrip-items .k-state-default.k-state-hover,
.k-ie7 .k-tabstrip-items .k-state-default .k-loading {
  border-color: @tabstrip-items-border;
}

// CJ 9/10
.k-tabstrip .k-state-active, 
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active,
.k-ie7 .k-tabstrip-items .k-state-active .k-loading {
  border-color: @tabstrip-items-border;
}


// -------------------------------------
//   List & Scheduler
// -------------------------------------

.k-list>.k-state-hover {
  border-color: @hover-background-color;
}

.k-scheduler-toolbar > ul > li {
  background-color: @drop-down-background;
  border-color: @secondary-border-color;
}

// List filter textbox - CJ 10/26
.k-list-filter > .k-textbox {
  border-width: 0;
  background-color: darken(@c-white, 2%);
  &:focus { .box-shadow(none); }
  &:hover {
    background-color: darken(@c-white, 2%);
  }
}


// -------------------------------------
//   Menu
// -------------------------------------

.k-menu {
  .k-group {
    background: @menu-active-background;
    border-color: @menu-border-color;
    border: 0;
  }
  .k-animation-container .k-menu-group {
    margin-left: 0;
  }
}

.k-menu .k-item  {
  
  &.k-state-hover {
    background-color: @menu-hover-background;
  }
  &.k-state-focused {
    .box-shadow(none);
  }

  & > .k-state-border-down {
    background-color: @menu-active-background;
    .box-shadow(none);
  }

  & > .k-state-active {
    border-color: @menu-border-color;
  }

  & > .k-state-border-right,
  & > .k-state-border-left {
    background-color: @selected-background-color;
    color: @selected-text-color;
  }

} // END k-item



// -------------------------------------
//   Input select arrow
// -------------------------------------

.k-textbox,
.k-autocomplete,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap {
  text-align: left;
}

.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-dropdown-wrap .k-select {
  background-color: transparent;
  border-color: transparent;
  color: @c-gray;
  .composite-background(@button-gradient);
  .k-state-selected {
    color: @c-black;
    background-color: @button-background-color;
  }
}

.k-numerictextbox .k-select .k-link {
  height: 50%;
  .k-icon {
    line-height: 11px; 
  }
}


// -------------------------------------
//   Slider
// -------------------------------------

.k-slider-track {
  background-color: @widget-background-color;
  border-color: @form-widgets-border-color;
  border-radius: 0;
}

.k-slider-selection {
  background-color: @slider-selection-background-color;
  border-radius: 0;
}

.k-slider-horizontal .k-tick {
  background-image:url('@{image-folder}/slider-h.gif');
}

.k-draghandle.k-state-selected,
.k-draghandle.k-state-selected:link,
.k-draghandle.k-state-selected:hover {
  background-color: @draghandle-selected-background;
  border-color: @draghandle-selected-background;
}

.k-draghandle:focus,
.k-draghandle.k-state-focused,
.k-draghandle.k-state-focused:link,
.k-slider-buttons .k-button:focus,
.k-slider-buttons .k-button:active {
  .box-shadow(@focused-shadow);
  border-color: @drop-down-focused-border-color;
  background-color: @widget-background-color;
  outline: 0;
}

.k-slider .k-button .k-icon {
  margin-right: 0;
}


// -------------------------------------
//   Inputs 
// -------------------------------------

.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default,
.k-multiselect.k-header {
  border-color: @form-widgets-border-color;
}

.k-widget.k-autocomplete,
.k-widget.k-combobox,
.k-widget.k-dropdown,
.k-widget.k-datepicker,
.k-widget.k-datetimepicker,
.k-widget.k-timepicker,
.k-widget.k-numerictextbox,
.k-widget.k-multiselect,
.k-widget.k-menu,
.k-widget.k-progressbar,
.k-progressbar > .k-state-selected {
  .box-shadow(none);
}

.k-autocomplete.k-state-default .k-input,
.k-combobox .k-state-default .k-input,
.k-picker-wrap.k-state-default .k-input,
.k-numeric-wrap.k-state-default .k-input,
.k-multiselect-wrap,
.k-slider-track,
.k-slider-selection,
.k-progress-status-wrap {
  .box-shadow(@form-widgets-shadow)
}

// Inverse Inputs
.k-inverse {
  &.k-autocomplete, 
  > .k-picker-wrap,
  > .k-numeric-wrap,
  > .k-dropdown-wrap {
    background-color: @c-inverse;
    border-color: transparent;
    &:hover,
    &.k-state-hover {
      border-color: transparent;
    }
    .k-input {
      color: @inverse-text-color;
    }
    .k-select {
      color: @inverse-text-color;
      .k-state-selected {
        color: @inverse-text-color;
        background-color: darken(@c-inverse, 10%);
      }
    } // END k-select
  }
} // END k-inverse

.k-textbox:hover,
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  // background-color: @select-hover-background-color;
  // .composite-background(@hover-gradient);
  // background-position: 50% 50%;
  border-color: @drop-down-hover-border-color;
}

.k-textbox:focus,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  // background-color: @widget-background-color;
  // .composite-background(@hover-gradient);
  // background-position: 50% 50%;
  border-color: @drop-down-focused-border-color;
  .box-shadow(@focused-shadow);
  .transition("box-shadow .15s ease-out, border-color .15s ease-out");
}

.k-multiselect .k-textbox:focus {
  .box-shadow(none);
}

.k-autocomplete.k-state-border-down,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-pager-numbers.k-state-expanded .k-current-page .k-link {
  border-color:  @drop-down-focused-border-color;
  // background-color: @select-background-color;
}

.k-picker-wrap.k-state-active.k-state-border-down,
.k-dropdown-wrap.k-state-active.k-state-border-down,
//.k-numeric-wrap .k-link.k-state-selected,
.k-pager-numbers.k-state-expanded .k-current-page .k-link {
  .box-shadow(@concave-shadow);
}

.k-picker-wrap.k-state-focused > .k-select,
.k-picker-wrap.k-state-active > .k-select .k-dropdown-wrap.k-state-focused > .k-select,
.k-dropdown-wrap.k-state-active > .k-select {
  border-color: @drop-down-focused-border-color;
}

// Input Background overlaying border-bottom problem - CJ 5/4
.k-autocomplete .k-input, 
.k-dropdown-wrap .k-input, 
.k-numeric-wrap .k-input, 
.k-picker-wrap .k-input, 
.k-selectbox .k-input {
  background: none;
  border-color: transparent;
}


// -------------------------------------
//   Checkbox & Radio
// -------------------------------------

.k-radio {
  &:checked + .k-radio-label {
    &:before {
      background-color: @radio-checked-background-color-before;
    }
  }
  &:disabled + .k-radio-label {
    &:before {
      border-color: @radio-disabled-border-color-before;
      background: @radio-disabled-background-color-before;
    }
  }
}


// -------------------------------------
//   Panelbar
// -------------------------------------

.k-panelbar > .k-item > .k-link {
  // border-color: @widget-border-color;
}

.k-panelbar > li.k-state-default > .k-link {
  color: @widget-text-color;
}

.k-panelbar .k-state-focused {
  .box-shadow(inset @focused-shadow);
}

.k-toolbar .k-button.k-state-active:hover,
.k-split-container .k-button.k-state-active:hover,
.k-overflow-container .k-button.k-state-active:hover,
.k-panelbar > li > .k-link.k-state-selected {
  color: @selected-text-color;
}

.k-toolbar .k-button.k-state-active,
.k-split-container .k-button.k-state-active,
.k-overflow-container .k-button.k-state-active,
.k-panelbar .k-link.k-state-selected.k-state-hover {
  color: @hover-text-color;
}

.k-panelbar .k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected,
.k-state-selected td.k-state-focused,
.k-listview>.k-state-focused.k-state-selected {
  .box-shadow(@focused-active-item-shadow);
}

.k-panelbar .k-state-focused.k-state-hover {
  background: @hover-background-color;
  .box-shadow(none);
}


// -------------------------------------
//   Window
// -------------------------------------

.k-window {
  border-color: @widget-border-color;
}
.k-window-actions {
  .k-window-action {
    color: @c-gray-dark;
  }
}

// Window Modal - CJ 8/12
// edit - Neil 12/04
.k-window {
  // max-width: 95%;
  // max-height: calc(~"90% - 2em"); // Because have Title - CJ 8/14
  border: 0 !important;
  // box-shadow: 0 2px 15px rgba(0, 0, 0, 0.6);
  background: none;
  // Without Title
  &.k-window-titleless {
    max-height: 90%;
  }
  &.k-state-focused {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.6);
  }
  .k-window-titlebar {
    padding: 0.8em 0;
    background-color: transparent;
    font-size: @font-size + 3px;
    font-weight: normal;
    color: @c-gray;
    text-align: center;
    border: 0;
  }
  // .k-window-content {
  //   padding: 0;
  //   background: none;
  // }
}


// -------------------------------------
//   Grid
// -------------------------------------

.k-grid {
  font-size: @font-size;
  border-color: @secondary-border-color;
}

.k-grid-content {
  background-color: darken(@c-white, 30%);
  tr {
    background-color: @widget-background-color;
    &.k-alt {
      background-color: @alt-background-color;
    }
    &.k-state-selected {
      color: darken(@c-primary, 18%); // CJ 7/22, 10/26
      background-color: lighten(@c-primary, 35%); // CJ 7/22
    }
  }
}

.k-grid tr {
  &:hover {
    .composite-background(@cell-hover-gradient);
  }
  &.k-state-selected {
    &:hover {
      .composite-background(none);
    }
  }
}

.k-grid td {
  white-space: nowrap;
  border-style: solid;
  border-color: @cell-border-color;
  border-width: 0 0 0 1px;
  font-size: 0.88rem;
}

.k-grid-header .k-link:link,
.k-grid-header .k-link:visited,
.k-grid-header .k-nav-current.k-state-hover .k-link,
.k-grouping-header .k-link {
  color: @widget-text-color;
}

.k-grid-header {
  border-color: @grid-header-border-color;
  background: @grid-header-gradient;
  .k-header {
    background: @grid-header-background-color;
    color: @grid-header-color;
    border-color: @grid-header-border-color;
    
    // REMOVED: It will affect too many elements..... - CJ 10/26
    // .k-icon {
    //   color: @grid-header-color;
    // }

    > .k-link { // Migrated from site.css
      .linearicons();
      &:before {
        position: relative;
        content: @icon-chevrons-expand-vertical;
        margin-right: 6px;
        top: -1.5px; // CJ 9/9
        line-height: normal;
        vertical-align: middle;
        font-size: @font-size - 3px;
      }
    }
  }
} // END k-grid-header

.k-grid-header th.k-header>.k-link {
  color: @grid-header-color;
  &:focus, &.k-state-focused {
    text-decoration: none;
  }
}

.k-grid-filter {
  .k-icon:before {
    font-size: 11px;
  }
  &.k-state-active {
    border-color: @hover-border-color;
    background-color: rgba(0, 0, 0, 0.2);
    .box-shadow(@selected-shadow);
  }
  &.k-state-border-down {
    border-color: @hover-border-color;
    background-color: rgba(0, 0, 0, 0.6);
    .box-shadow(@selected-shadow);
  }
} // END k-grid-filter

.k-grid-toolbar {
  background: @grid-header-background-color;
  color: @grid-header-color;
  border-color: @grid-header-border-color;
}


// ----- Page wrap ----- //

.k-pager-wrap {
  color: @pager-text-color;
  border-width: 1px 0 0 0;
}

.k-pager-wrap .k-link {
  color: @c-gray-dark;
  border-color: @secondary-border-color;
  border-radius: 3px;
  &:hover {
    background-color: @button-background-color;
  }
  .k-icon {
    position: relative;
    top: -2px;
  }
  &.k-state-disabled {
    opacity: 0.2;
    &:hover {
      color: @c-gray-dark;
      background: none;
    }
  }
}

.k-pager-wrap > .k-link {
  margin: 0 2px;
}

.k-pager-wrap .k-link:hover {
  color: @pager-hover-color;
  background: @hover-background-color;
  border-color: transparent;
}

.k-pager-wrap .k-link.k-pager-last {
  border-right-width: 1px;
  border-right-style: solid;
}

.k-pager-wrap .k-pager-refresh {
  background: @header-background-color;
}

.k-pager-wrap .k-pager-nav {
  border-color: transparent;
  &:hover {
    background-color: @c-overlay;
  }
}

.k-pager-wrap .k-pager-info {
  line-height: 2em;
}

.k-pager-wrap .k-dropdown {
  width: 5.0em; // this width only can show 1xx number - CJ 5/4
}

// ----- Page Numbers ----- //

.k-grid .k-pager-numbers {
  margin: 0;
}

.k-pager-numbers .k-link,
.k-pager-numbers .k-state-selected {
  margin: 0 2px;
  border-radius: 3px;
  border-color: transparent;
}

.k-pager-numbers .k-current-page {
  display: none;
}

.k-pager-numbers .k-state-selected {
  border-color: @primary-button-border-color;
  line-height: 2em;
}


// -------------------------------------
//   Upload
// -------------------------------------

.k-dropzone .k-upload-status {
  color: @upload-status-color;
}

.k-upload-button {
  &.k-state-focused {
    border-color: transparent;
  }
}
// 12/11 Neil
.k-widget.k-upload.k-header{
  border: 0;
}
// 12/11 Neil
.k-file{
  border-color: @c-white;
  .k-filename{
    font-size: 0.88rem;
    margin: 0 0 0 5px;
    padding: 0;
    line-height: 38px;
  }
}
// 12/11 Neil
.k-upload-status-total .k-icon{
  line-height: 70px!important;
}
// 12/11 Neil
.k-upload-status{
  top: 0;
  height: 100%;
  line-height: 50px;
  .k-button{
    padding: 0!important;
    font-size: 1rem!important;
    .k-icon{
      height: 100%;
      margin: 0;
      line-height: inherit;
    }
  }
  .k-icon{
    height: 100%;
    line-height: 49px;
  }
}



// -------------------------------------
//   Common Shadow
// -------------------------------------

.k-menu .k-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-datepicker-calendar,
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-multiselect.k-state-focused,
.k-filebrowser .k-image,
.k-tooltip {
  // .box-shadow(none);
}


// -------------------------------------
//   States
// -------------------------------------

.k-state-focused,
.k-button:focus,
.k-button.k-state-focused,
.k-button:focus:active {
  .box-shadow(@focused-shadow);
}

.k-list>.k-state-focused {
  .box-shadow(none);
}

.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  .box-shadow(none);
}

.k-state-disabled {
  opacity: 0.7;
}

// disabled Word Hightlight - CJ 10/26
.k-selectable {
  .user-select(none);
}


// -------------------------------------
//   Popup & Tooltip
// -------------------------------------

.k-popup {
  .box-shadow(0 5px 10px @group-border-color);
}

.k-tooltip {
  .box-shadow(none);
  &.k-widget {
    border-radius: 3px;
  }
  .k-callout {
    background: none;
  }
}

// Callout Triangle
.k-callout-n {
  border-bottom-color: @tooltip-background-color;
}
.k-callout-w {
  border-right-color: @tooltip-background-color;
}
.k-callout-s {
  border-top-color: @tooltip-background-color;
}
.k-callout-e {
  border-left-color: @tooltip-background-color;
}


.k-list-container {
  border-color: @form-widgets-border-color;
  &.k-popup {
    padding: 0;
  }
}

// Validation
.k-widget.k-tooltip-validation {
  border-color: transparent;
  background-color: transparent;
  text-align: left;

  position: absolute;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  background: #efd3d3;
  color: #d9534f;
}

// Inside Grid Cell
.k-edit-cell, 
.k-grid-edit-row {
  .k-tooltip-validation {
    background-color: @validation-background-color;
  }
}


// -------------------------------------
//   Draghandle
// -------------------------------------

.k-flatcolorpicker .k-hue-slider .k-draghandle {
  .box-shadow(@draghandle-shadow);
  background: transparent;
  border: 5px solid @draghandle-border-color;
  &:hover, &:focus {
    border-color: @draghandle-border-color;
    .box-shadow(0 0 8px 0 rgba(102, 175, 233, 0.75));
    border-width: 5px;
    padding: 0;
  }
}


// -------------------------------------
//   Primary Items
// -------------------------------------

.k-primary:active {
  color: @primary-active-text-color;
  border-color: @primary-active-border-color;
  .composite-background(@primary-active-gradient);
  background-color: @primary-active-background-color;
  .box-shadow(@primary-active-shadow);
}


// -------------------------------------
//   Editor Toolbar
// -------------------------------------

.k-editor-toolbar .k-tool {
  color: @secondary-text-color;
  background-color: @button-background-color;
  border-color: @secondary-border-color;
}

.k-editor-toolbar .k-tool.k-state-hover {
  color: @hover-text-color;
  border-color: @hover-border-color;
  background-color: @hover-background-color;
}

.k-editor-toolbar .k-state-selected {
  .box-shadow(@concave-shadow);
  background-color: @select-background-color;
  border-color: @hover-border-color;
}

.k-editor-toolbar .k-button-group .k-tool-icon {
  border-color: @secondary-border-color;
}


// -------------------------------------
//   SplitBar
// -------------------------------------

.k-splitbar,
.k-splitbar:hover {
  border-color: transparent;
}

.k-splitbar-vertical .k-resize-handle:before {
  .transform(rotate(90deg));
}


// -------------------------------------
//   Progress Bar
// -------------------------------------

.k-progressbar {
  border-color: transparent;
  border-radius: 0;
  > .k-state-selected {
    border-radius: 0;
  }
}


// -------------------------------------
//   Calendar
// -------------------------------------

.k-calendar {
  border-color: @secondary-border-color;
  th {
    background-color: @header-background-color;
    border-color: @secondary-border-color;
  }
}



// -------------------------------------
//   Button
// -------------------------------------

.k-button {
  margin: 0 5px; // Default margin
  padding: 6px 15px;
  font-size: @font-size;
  line-height: normal;  // TEMP: overwrite bootstrap & fontawesome line-height - CJ 5/4
  vertical-align: top;
  // line-height: 1.42857143; // Same with bootstrap
  // &.fa {
  //   line-height: 1; // If have fa, line-height reset to 1
  // }
  //.box-shadow(none) !important;
  .user-select(none);
  .composite-background(@button-gradient);
  &:hover, 
  &.k-state-hover {
    color: @button-hover-text-color;
    border-color: @button-hover-border-color;
    background: @button-hover-background-color;
    //.composite-background(@button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    text-decoration: none;
    color: @button-hover-text-color;
    border-color: @button-focused-border-color;
    background: @button-active-background;
    .box-shadow(@button-focused-active-shadow);
    //.composite-background(@button-focused-gradient);
  }
  &:focus,
  &.k-state-focused {
    .box-shadow(none);
  }
  &.k-state-disabled {
    pointer-events: none;
  }
  .k-icon, .icon, .fa {
    margin-right: 5px;
  }
}

// Clear Button
.k-button.k-clear {
  background: none;
  &:hover, 
  &.k-state-hover {
    color: @button-hover-text-color;
    border-color: @button-hover-border-color;
    background: none;
    //.composite-background(@button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @button-hover-text-color;
    border-color: @button-focused-border-color;
    background: none;
    text-shadow: @button-focused-active-text-shadow;
    //.composite-background(@button-focused-gradient);
  }
}


// ----- Primary Buttons ----- //

.k-button.k-primary {
  .composite-background(@primary-button-gradient);
  &:hover, 
  &.k-state-hover {
    color: @primary-button-text-color;
    border-color: @primary-button-hover-border-color;
    background: @primary-button-hover-background-color;
    //.composite-background(@primary-button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @primary-button-text-color;
    border-color: @primary-button-hover-border-color;
    background: @primary-button-active-background-color;
    .box-shadow(@button-focused-active-shadow) !important;
    //.composite-background(@primary-button-focused-gradient);
  }
}

// Clear Button
.k-button.k-primary.k-clear {
  background: none;
  color: @primary-button-background-color;
  &:hover, 
  &.k-state-hover {
    color: @primary-button-hover-background-color;
    border-color: @primary-button-hover-border-color;
    background: none;
    //.composite-background(@primary-button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @primary-button-active-background-color;
    border-color: @primary-button-hover-border-color;
    background: none;
    text-shadow: @primary-button-focused-active-text-shadow;
    .box-shadow(none) !important;
    //.composite-background(@primary-button-focused-gradient);
  }
}

// ----- Inverse Buttons ----- //

.k-button.k-inverse {
  color: @inverse-button-text-color;
  border-color: @inverse-button-border-color;
  .composite-background(@inverse-button-gradient);
  background: @inverse-button-background-color;
  &:hover, 
  &.k-state-hover {
    color: @inverse-button-text-color;
    border-color: @inverse-button-hover-border-color;
    background: @inverse-button-hover-background-color;
    //.composite-background(@inverse-button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @inverse-button-text-color;
    border-color: @inverse-button-hover-border-color;
    background: @inverse-button-active-background-color;
    //.composite-background(@inverse-button-focused-gradient);
  }
}

// Clear Button
.k-button.k-inverse.k-clear {
  color: @inverse-button-background-color;
  background: none;
  &:hover, 
  &.k-state-hover {
    color: @inverse-button-hover-background-color;
    border-color: @inverse-button-hover-border-color;
    background: none;
    //.composite-background(@inverse-button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @inverse-button-active-background-color;
    border-color: @inverse-button-hover-border-color;
    background: none;
    text-shadow: @inverse-button-focused-active-text-shadow;
    //.composite-background(@inverse-button-focused-gradient);
  }
}

// ----- Multiselect Button ----- //

.k-multiselect .k-button {
  // Extended .k-button.k-inverse
  color: @inverse-button-text-color;
  border-color: @inverse-button-border-color;
  .composite-background(@inverse-button-gradient);
  background: @inverse-button-background-color;
  &:hover, 
  &.k-state-hover {
    color: @inverse-button-text-color;
    border-color: @inverse-button-hover-border-color;
    background: @inverse-button-hover-background-color;
    //.composite-background(@inverse-button-hover-gradient);
  }
  &:focus,
  &.k-state-focused,
  &:active,
  &.k-state-active:active,
  &:active:hover,
  &.k-state-active:hover {
    color: @inverse-button-text-color;
    border-color: @inverse-button-hover-border-color;
    background: @inverse-button-hover-background-color;
    //.composite-background(@inverse-button-focused-gradient);
  }
  // Individual Settings
  border-radius: @multiselect-buttons-border-radius;
  span:not(.k-icon) {
    display: inline-block;
    padding-right: 10px;
    border-right: 1px solid darken(@inverse-button-border-color, 5%);
  }
  .k-delete {
    position: relative;
    top: -1px;
    margin-left: 2px;
    margin-right: 0;
    // color: @c-white;
    &:before {
      font-size: 11px;
    }
  }
}


// -------------------------------------
//   Icons
//   - replace k-icon to fontawesome
// -------------------------------------

.k-icon {
  display: inline-block;
  background: none !important;
  .linearicons();
  // &.k-i-arrow-n:before { content: @icon-chevron-up; .transform(scale(0.6)); }
  &.k-i-arrow-n:before { content: @icon-chevron-up;  }
  &.k-i-arrow-s:before { content: @icon-chevron-down;  }
  &.k-i-arrow-e:before { content: @icon-chevron-right;  }
  &.k-i-arrow-w:before { content: @icon-chevron-left;  }
  &.k-i-seek-n:before { content: @icon-chevron-up;  }
  &.k-i-seek-n:after { content: @icon-chevron-up;  position: relative; right: 2px; }
  &.k-i-seek-s:before { content: @icon-chevron-down;  }
  &.k-i-seek-e:before { content: @icon-chevron-right;  }
  &.k-i-seek-e:after { content: @icon-chevron-right;  position: relative; right: 2px;  }
  &.k-i-seek-w:before { content: @icon-chevron-left;  }
  &.k-i-close:before { content: @icon-cross; }
  &.k-i-calendar:before { content: @icon-calendar-full; }
  &.k-i-clock:before { content: @icon-clock3; }
  &.k-i-search:before { content: @icon-magnifier; }
  &.k-add:before { content: @icon-plus; }
  &.k-delete:before { content: @icon-cross; }
  &.k-filter:before { content: @icon-funnel; }
  &.k-warning:before { content: @icon-warning; }
  &.k-i-expand:before, &.k-plus:before { content: @icon-chevron-right;  }
  &.k-i-collapse:before, &.k-minus:before{ content: @icon-chevron-right; .transform(rotate(45deg)); }
  &.k-resize-handle:before { content: "|"; }
  &.k-i-note:before { content: @icon-notification-circle; }
  &.k-i-refresh:before {
    content: @icon-sync;
  }
  &.k-edit:before {
    content: @icon-pencil;
  }
  .k-file > &:before {
    content: @icon-file-empty;
  }
  &.k-i-jpg:before, &.k-i-png:before, &.k-i-gif:before {
    content: @icon-file-image;
  }
  &.k-i-xml:before {
    content: @icon-file-code;
  }
  &.k-i-doc:before, &.k-i-docx:before, &.k-i-dot:before {
    content: @icon-file-stats;
  }
  &.k-i-xlsx:before, &.k-i-xls:before, &.k-i-xltx:before, &.k-i-xlt:before, &.k-i-xlsb:before, &.k-i-xlsm:before, &.k-i-xltm:before {
    content: @icon-file-spreadsheet;
  }
  &.k-i-zip:before, &.k-i-7z:before, &.k-i-tar:before, &.k-i-gz:before {
    content: @icon-file-zip;
  }
  &.k-i-tick:before {
    content: @icon-check;
  }
  &.k-loading:before {
    content: @icon-sync;
  }  
}

// Dirty Icon
.k-dirty {
  display: none;
}

.k-button{
  .transition("all .15s ease-in");
}

.k-overlay{
  background: rgba(255, 255, 255, 0.95);
  opacity: 1!important;
}

//show pager words
.k-pager-nav span.k-icon{
  font-size: 0.88rem;
  width: auto;
  padding: 0 10px;
}
.k-pager-nav span.k-i-arrow-e:before{
  content:'';
}
.k-pager-nav span.k-i-arrow-e:after{
  content:'\e93c';
  margin-left: 5px;
}
.k-pager-nav.k-pager-last span.k-i-seek-e:before{
  content:'';
}
.k-pager-nav.k-pager-last span.k-i-seek-e:after{
  margin-left: 5px;
}





// -------------------------------------
//   Responsive styles
// -------------------------------------

@media only screen and (max-width: 1024px) {
  .k-webkit,
  .k-ff,
  .k-ie11 {
    .k-pager-wrap .k-pager-nav, .k-pager-input {
      float: none;
      display: inline-block;
      vertical-align: top;
    }
    .k-pager-numbers,
    .k-grid .k-pager-numbers {
      position: absolute;
      display: -moz-inline-flex;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-transform: translatey(-100%);
      -moz-transform: translatey(-100%);
      transform: translatey(-100%);
      margin-left: .3em;
    }
    .k-rtl .k-pager-numbers,
    .k-rtl .k-grid .k-pager-numbers {
      right: 5.18em;
      width: 4.45em
    }
    .k-pager-numbers .k-current-page,
    .k-grid .k-pager-numbers .k-current-page {
      display: block;
      border-left: 0;
      -webkit-transform: translatey(100%);
      -moz-transform: translatey(100%);
      transform: translatey(100%);
    }
    .k-pager-numbers li:not(.k-current-page) {
      display: none;
    }
    .k-pager-numbers .k-current-page .k-link {
      width: 3.2em;
      padding: 0 .429em 0 .714em;
      border-radius: @main-border-radius;
      border-right: 1px solid @secondary-border-color;
      color: @widget-text-color;
      background-color: @widget-background-color;
    }
    .k-pager-numbers .k-link {
      color: @widget-text-color;
    }
    .k-pager-nav.k-pager-first + .k-link {
      border-right: 1px solid @secondary-border-color;
      border-radius: 0 @main-border-radius @main-border-radius 0;
    }
    .k-rtl .k-pager-nav.k-pager-first + .k-link {
      border-right: 0;
      border-radius: @main-border-radius 0 0 @main-border-radius;
    }
    .k-pager-numbers + .k-link {
      //margin-left: 5.6em; // Removed - CJ 5/4
      border-radius: @main-border-radius 0 0 @main-border-radius;
    }
    .k-rtl .k-pager-numbers + .k-link {
      //margin-right: 5.6em; // Removed - CJ 5/4
      margin-left: 0;
      border-radius: 0 @main-border-radius @main-border-radius 0;
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: @widget-border-color;
    }
    .k-pager-numbers .k-state-selected,
    .k-pager-numbers .k-link {
      display: block;
      margin-right: 0;
      padding: 1px 5px 1px 5px;
      text-align: left;
    }
    .k-pager-numbers.k-state-expanded {
      box-sizing: border-box;
      padding: 2px 2px 0;
      background-color: @widget-background-color;
    }
    .k-pager-numbers.k-state-expanded .k-current-page {
      margin: -2em -3px 0;
      padding: 0;
    }
    .k-pager-numbers.k-state-expanded .k-current-page .k-link {
      border-radius: 0 0 @main-border-radius @main-border-radius;
    }
    .k-pager-numbers.k-state-expanded li {
      display: inline-block;
    }
    .k-pager-numbers.k-state-expanded li:not(.k-current-page) .k-link {
      border: 0;
      background-color: @widget-background-color;
      border-radius: @inner-border-radius;
    }
    .k-pager-numbers .k-state-selected {
      border-radius: @inner-border-radius;
    }
    .k-pager-numbers.k-state-expanded li:not(.k-current-page) .k-link:hover {
      color: @hover-text-color;
      background-color: @hover-background-color;
    }
  }
}

@media only screen and (max-width: 640px) {
  .k-webkit,
  .k-ff,
  .k-ie11 {
    .k-pager-info {
      display: none;
    }
  }
}

@media only screen and (max-width: 480px) {
  .k-webkit,
  .k-ff,
  .k-ie11 {
    .k-pager-sizes {
      display: none;
    }
  }
}
